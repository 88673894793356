import * as styles from '../page-styles/Contact.module.css';
import Card from '../components/ui/Card/Card';
import Layout from '../components/Layout/Layout';

import facebookLogo from '../images/facebook-logo-primary.png';
import instagramLogo from '../images/instagram-logo-primary.png';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

import { useEffect } from 'react';
import * as React from 'react';

const Contact = ({data}) => {

    const pageData = data.allDataYaml.edges[0].node;

    useEffect(()=>{
        if(typeof window !== 'undefined'){
        window.scrollTo(0,0)
        }  
}, [])
    
    return (
        <Layout location='contact'>
             <Seo 
         title={pageData.title}
         description={pageData.description}/>
            
        <main>
            <Card style={{ width: '90vw', maxWidth: '1100px', margin: 'auto' }}>
                <div className={styles.contact}>
                    <h1>Get in touch with me</h1>
                    <div className={styles.titleUnderline}></div>

                    <div className={styles.grid}>
                        <div className={styles.contactInfo}>
                            <h2>Contact Info</h2>

                            <h3>Phone</h3>
                            <p>724-261-0032</p>

                            <h3>Email</h3>
                            <p>kolonaym@gmail.com</p>
                        </div>


                        <div className={styles.socialLinks}>
                            <h2>Social Links</h2>
                <div className={styles.socialLinkFlex}>

                            <div className={styles.socialLinkWrapper}>
                                <a href='https://www.facebook.com/MichaelKolonayRenovations' target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                                    <img src={facebookLogo} alt="facebook" />
                                </a>
                                <a className={styles.linkText} href='https://www.facebook.com/MichaelKolonayRenovations' target="_blank" rel="noopener noreferrer">facebook.com/MichaelKolonayRenovations</a>
                            </div>

                            <div className={styles.socialLinkWrapper}>
                                <a href='https://www.instagram.com/michael_kolonay_renovations/' target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                                    <img src={instagramLogo} alt="instagram" />
                                </a>
                                <a className={styles.linkText} href='https://www.instagram.com/michael_kolonay_renovations/' target="_blank" rel="noopener noreferrer"> instagram.com/michael_kolonay_renovations</a>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Card>
        </main>
        </Layout>
    )
}

export const query = graphql`
query contactQuery {
    allDataYaml(filter: {page: {eq: "contact"}}) {
      edges {
        node {
          description
          title
        }
      }
    }
  }
  
`

export default Contact;